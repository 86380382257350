import type { ConctactFormsPostTextFields } from "../../translation-keys/contact-post-submit-text-keys.js";

const contactFormText: ConctactFormsPostTextFields = {
  "contact-message": `For immediate assistance, please call us at {phone-number-slot}`,
  "contact-phone-number": "+1-866-747-8767.",
  "contact-thank-you": "Thanks for your message!",
  "contact-href-value": "tel:+18667478767",
  "qx-roi-message": "",
  "mx-roi-message": "",
  "spark-message": "",
  "pricing-message": "We have received your request for pricing information and will be in touch shortly.",
  "pricing-thank-you" : "Thank You!",
  "pricing-rc-statement": "In the meantime, you can continue learning about MasterControl at our ",
  "pricing-rc": "resource center.",
  "subscription-message": "Thank you for subscribing.",
  "partner-message": "Thank you for submitting your request. We will return your inquiry within 2 business days.",
  "demo-request-message": "Thank you for your request. A representative will be in touch with you to schedule your demo. We look forward to showing off our Quality solution.",
  "tradeshow-giveaway-message": "Thank you! You are now entered. We will contact the winner within five business days.",
  "event-registration-message": "Thank you for registering!",
  "subprocessor-update-notification-message": "",
  "ppc-contact-message": "",
  "raise-your-hand": "Thank you for your interest!",
  "event-registration-support-copy": "You will receive a confirmation email with the event details shortly."
};
export default contactFormText;